<template>
  <div class="box">
    <header class="head">
      <div class="container">Destek</div>
    </header>
    <article class="container article">
      <vui-collapse v-model="activeNames">
        <vui-collapse-item icon-position="right" name="1">
          <template slot="title">
            <div class="title">
              <img class="destekImg" src="@/assets/kullborder.png" alt="" />
              <div class="destekTitle">
                <span>Kullanım Kılavuzları</span>
                <span>Ürün Kullanım Kılavuzlarını indirin</span>
              </div>
            </div>
          </template>
        </vui-collapse-item>

        <vui-collapse-item icon-position="right" name="2">
          <template slot="title">
            <div class="title">
              <img class="destekImg" src="@/assets/sertifikalarborder.png" alt="" />
              <div class="destekTitle">
                <span>Sertifikalar - Uygunluk Beyanı (DoC)</span>
                <span>Ürün Sertifikası - AB Uygunluk Beyanını görüntüleyin.</span>
              </div>
            </div>
          </template>
          <ul class="fileList">
            <!-- Türkçe -->
            <li class="listItem title">Türkçe</li>
            <li class="listItem" v-for="(item, index) in fileList" :key="index">
              <img src="@/assets/PDF.png" alt="" />
              <div>{{ item.name }}</div>
              <span @click="handleClick(item)">indir</span>
            </li>
            <!-- En -->
            <li class="listItem title">English</li>
            <li class="listItem"  v-for="(item, index) in fileEnList" :key="index">
              <img src="@/assets/PDF.png" alt="" />
              <div>{{ item.name }}</div>
              <span @click="handleClick(item)">indir</span>
            </li>
          </ul>
        </vui-collapse-item>

        <vui-collapse-item icon-position="right" name="3">
          <template slot="title">
            <div class="title">
              <img class="destekImg" src="@/assets/yetkiliborder.png" alt="" />
              <div class="destekTitle">
                <span>Yetkili Servis Listesi</span>
                <span>En Yakın Yetkili Servisi bulun</span>
              </div>
            </div>
          </template>
          <vui-select v-model="selectVal" @change="handleChangeCity" size="medium" placeholder="请选择">
            <vui-option v-for="(item, index) in options" :key="index" :label="item" :value="item"> </vui-option>
          </vui-select>
          <div class="adanaContainer">
            <section v-for="(item, index) in cityInfoList" :key="index" class="section">
              <div class="info">
                <label for="" class="label">{{ curCity }}</label>
                <p ref="customer" class="top">{{ item.customer }}</p>
                <p ref="adress" class="adress">{{ item.adress }}</p>
              </div>
              <div>
                <p><span class="top">Telefon:</span> {{ item.tel }}</p>
                <p v-if="item.email && item.email !== ''"><span class="top">E-posta:</span> {{ item.email }}</p>
              </div>
            </section>
          </div>
        </vui-collapse-item>
      </vui-collapse>
    </article>
  </div>
</template>

<script>
// @ is an alias to /src 

export default {
  name: 'Destek',
  created() {
    this.curCity = 'UŞAK'
    this.cityInfoList = this.cityInfoData.UŞAK
    this.activeNames = this.$route.query.code || '2'
  },
  data() {
    return {
      fileList: [
        { name: 'V2027_Y20_AB_Uygunluk_Beyanı.pdf', url: '' },
        { name: 'V2028_Y11S_AB_Uygunluk_Beyanı.pdf', url: '' },
        { name: 'V2058_Y53S_AB_Uygunluk_Beyanı.pdf', url: '' },
        { name: 'V2109_Y33S_AB_Uygunluk_Beyanı.pdf', url: '' },
        { name: 'V2110_Y21S_AB_Uygunluk_Beyanı.pdf', url: '' },
        { name: 'V2111_Y21_AB_Uygunluk_Beyan.pdf', url: '' },
        { name: 'V2120_AB_UYGUNLUK_BEYANI.pdf', url: '' },
        { name: 'V2204_EU_DOC_V1_TUR.pdf', url: '' },
        { name: 'V2205_Turkey_DOC_local.pdf', url: '' },
        { name: 'V2206_Turkey_DOC_local.pdf', url: '' },
        { name: 'V2247AB_UYGUNLUK_BEYANI.pdf', url: '' },
        { name: 'V2249_AB_UYGUNLUK_BEYANI.pdf', url: '' },
        { name: 'V2310_AB_UYGUNLUK_BEYANI.pdf', url: '' },
        { name: 'V2244_AB_UYGUNLUK_BEYANI.pdf', url: '' },
        { name: 'V2342_EU_DOC_V5_EN_Turkey.pdf', url: '' },
        { name: 'V2352_AB_UYGUNLUK_BEYANI.pdf', url: '' },
      ],

      fileEnList: [
        { name: 'V2027_Y20_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2028_Y11S_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2058_Y53S_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2110_Y21S_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2109_Y33S_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2111_Y21_EU Declaration_ of_Conformity.pdf', url: '' },
        { name: 'V2120_EU_Declaration_of_Conformity.pdf', url: '' },
        { name: 'V2204_EU_DOC_V1_EN.pdf', url: '' },
        { name: 'V2205_Turkey_DOC_EN.pdf', url: '' },
        { name: 'V2206_Turkey_DOC_EN.pdf', url: '' },
        { name: 'V2247_EU_DOC_V5_EN_Turkey.pdf', url: '' },
        { name: 'V2249_EU_DOC_V5_EN_Turkey.pdf', url: '' },
        { name: 'V2310_EU_DOC_V5_EN_Turkey.pdf', url: '' },
        { name: 'V2244_EU_DOC_V5_EN_Turkey.pdf', url: '' },
        { name: 'V2342_AB_UYGUNLUK_BEYANI.pdf', url: '' }, 
        { name: 'V2333_EU_DOC_V5.pdf', url: '' },
        { name: 'V2352_EU_DOC_V5_EN_Turkey.pdf', url: '' }, 
      ],
      activeNames: '2',
      selectVal: 'UŞAK',
      curCity: '',
      options: ['UŞAK', 'İSTANBUL1', 'İSTANBUL2', 'ANKARA', 'ADANA', 'BURSA', 'İZMİR', 'KARS', 'ANTALYA', 'AFYONKARAHİSAR', 'KAHRAMANMARAŞ', 'TRABZON', 'DİYARBAKIR'],
      cityInfoData: {
        UŞAK: [
          {
            customer: 'ASSOS -SELİM ÇELİKDEMİR',
            adress: 'İSLİCE MAH ANNAÇ SOK NO:8/A MERKEZ/UŞAK',
            tel: '(0276) 223 68 13',
            email: '',
          },
          {
            customer: 'ENCTECH TEKNOLOJİ MARKET-İBRAHİM GÖRAL',
            adress: 'ÜNALAN MAHALLESİ CİMCİM SOKAĞI No:1 A/- UŞAK MERKEZ/UŞAK / UŞAK MERKEZ',
            tel: '(0555)313 22 44',
            email: '',
          },
        ],
        İSTANBUL1: [
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİM HİZMETLERİ SANAYİ TİC.A.Ş./KADIKÖY ŞUBE',
            adress: 'HASANPAŞA MAH. FAHRETTİN KERİM GÖKAY CAD. NO:39-41/B KADIKÖY/İSTANBUL',
            tel: '(0216)345 10 10-(0212)359 03 59',
            email: '',
          },
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİŞİM HİZMETLERİ SANAYİ TİCARET A.Ş.',
            adress: 'ETİLER NİSPETİYE CAD. NO:101',
            tel: '(0212) 287 17 17',
            email: '',
          },
        ],
        İSTANBUL2: [
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - BAKIRKOY  ŞUBESİ',
            adress: 'KARTALTEPE MAHALLESİ, İNCİRLİ CADDESİ, İHSAN KALMAZ SOKAK, NO.3/1-2',
            tel: '(0212) 660 59 59',
            email: '',
          },
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET A.Ş.',
            adress: 'YENİ MAH. SOĞANLIK D-100 KUZEY YAN YOL C.KANAT SAN. SİT. ENİ MAH. SOĞANLIK D-100 KUZEY YAN YOL C.KANAT SAN. SİT. NO:32-2/1 KARTAL/İSTANBUL',
            tel: '(0216) 452 80 54',
            email: '',
          },
        ],
        ANKARA: [
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - ANKARA ŞUBESİ',
            adress: 'Kızılay Mahallesi Gazi Mustafa Kemal Bulvarı Fevzi Çakmak 1 Sokak Oran Apartmanı No:15/B ÇANKAYA',
            tel: '(0312) 430 43 43',
            email: '',
          },
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİŞİM HİZMETLERİ SANAYİ TİCARET ANONİM ŞİRKETİ - ANKARA ŞUBESİ',
            adress: 'Kızılay Mahallesi Necatibey Caddesi No:51/2 ÇANKAYA',
            tel: '(0312) 232 60 70',
            email: '',
          },
        ],
        ADANA: [
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİŞİM HİZMETLERİ SANAYİ TİCARET ANONİM ŞİRKETİ - ADANA ŞUBESİ',
            adress: 'CEMALPAŞA MAHALLESİ 63005 SK. AYDINOĞLU İŞ MERKEZİ Apt. NO: 27 A SEYHAN',
            tel: '(0322)458 98 38',
            email: '',
          },
        ],
        BURSA: [
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - ADANA ŞUBESİ',
            adress: 'REŞATBEY MAH.ATATÜRK CAD.62003 SOKAK GÜÇLÜ APT.NO.29 SEYHAN-ADANA',
            tel: '(0322) 459 30 74',
            email: '',
          },
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİŞİM HİZMETLERİ SANAYİ TİCARET ANONİM ŞİRKETİ - BURSA ŞUBESİ',
            adress: 'KIRCAALİ MAH. KAYALI SOK. NO:34/42-43 OSMANGAZİ',
            tel: '(0224)250 88 11',
            email: '',
          },
        ],
        İZMİR: [
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - İZMİR  ŞUBESİ',
            adress: 'İSMET KAPTAN MAHALLESİ, 1362. SOKAK, BÜYÜK ÇAMLIBEL İŞ HANI, NO.7 B-7 F KAT.1 106-107 ÇANKAYA - KONAK',
            tel: '(0232) 489 59 59',
            email: '',
          },
          {
            customer: 'GEN-PA TELEKOMÜNİKASYON VE İLETİŞİM HİZMETLERİ SANAYİ TİCARET ANONİM ŞİRKETİ - İZMİR KONAK ŞUBESİ',
            adress: 'İSMET KAPTAN MAHALLESİ ŞAİR EŞREF BULVARI NO:26 ZEMİN KAT NO:26-B ÇANKAYA KONAK/İZMİR',
            tel: '(0232) 446 36 32',
            email: '',
          },
        ],
        KARS: [
          {
            customer: 'İLKE İLETİŞİM BİLGİSAYAR VE SAĞLIK HİZMETLERİ İNŞAAT ELEKTRİK ELEKTRONİK SANAYİ VE TİCARET LİMİTED ŞİRKETİ',
            adress: 'ORTA KAPI MAHALLESİ FAİKBEY CADDESİ NO:25',
            tel: '(0474) 212 31 10',
            email: '',
          },
        ],
        TRABZON: [
          {
            customer: 'SEÇKİN ELEKTRONİK CİHAZLARI TİC.VE SERVİS HİZMETLERİ LTD. ŞTİ. ALİ ATALAR',
            adress: 'ÇARŞI MAHALLESİ ALİ CAN SOKAK NO:7',
            tel: '(0462)322 25 19',
            email: '',
          },
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - TRABZON ŞUBESİ',
            adress: 'CUMHURİYET MAH. NEMLİOĞLU CEMAL SOK. ZİYABEY SİTESİ A BLOK NO. 16/B',
            tel: '(0462)321 64 02',
            email: '',
          },
        ],
        ANTALYA: [
          {
            customer: 'KVK TEKNİK SERVİS HİZMETLERİ VE TİCARET ANONİM ŞİRKETİ - ANTALYA  ŞUBESİ',
            adress: 'ETİLER MAHALLESİ ADNAN MENDERES BULVARI NO:67 B MURATPAŞA',
            tel: '(0242) 243 28 28',
            email: '',
          },
        ],
        AFYONKARAHİSAR: [
          {
            customer: 'OSMAN AKIN AKIN ELEKTRONİK',
            adress: 'DUMLUPINAR MH BAYBURTLU YZB AGAH CD BAYKENTLER APT NO:15',
            tel: '(0272) 215 80 01',
            email: '',
          },
        ],
        KAHRAMANMARAŞ: [
          {
            customer: 'YAVUZ SELİM SAÇAK ASES VIP SERVİS SAHIS',
            adress: 'ŞEHİT EVLİYA MAHALLESİ 65023. SOK. No:1 B/- ONİKİŞUBAT / KAHRAMANMARAŞ ONİKİŞUBAT',
            tel: '(0344)215 02 12',
            email: '',
          },
          {
            customer: 'OSMAN TÜRKMEN - TÜRKMEN TEKNİK SOĞUTMA',
            adress: 'HAYRULLAH MAH. SANDALZADE BLV. ONBEŞ TEMMUZ APT. NO:23 A ONİKİŞUBAT',
            tel: '(0344)221 13 97',
            email: '',
          },
        ],
        DİYARBAKIR: [
          {
            customer: 'SENTO TEKNİK SERVİS-MEHMET EMİN MAÇO',
            adress: 'FIRAT MAHALLESİ 523. SOK. No:25 AC/- KAYAPINAR / DİYARBAKIR KAYAPINAR',
            tel: '(0412)235 30 93',
            email: '',
          },
        ],
      },
      cityInfoList: [],
    }
  },
  methods: {
    handleClick(item) {
      let url = 'http://www.vtechtr.com/' + item.name
      window.open(url, '_blank')
    },
    handleChangeCity(val) {
      this.curCity = val
      this.cityInfoList = this.cityInfoData[val]
    },
  },
}
</script>
<style scoped lang="less">
.box {
  // *min-height: calc(100% - 362px);
  width: 100%;
  min-height: 575px;
}
.head {
  background: #242933;
  height: 60px;
  div {
    text-align: left;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 60px;
  }
}
/deep/ .container {
  .vui-collapse.el-collapse .el-collapse-item {
    border: none;
    margin-top: 20px;
  }
  .vui-collapse.el-collapse .el-collapse-item:not(.no-background) .el-collapse-item__wrap {
    border-top: none;
  }
  .vui-collapse.el-collapse .el-collapse-item__header {
    background: #f7fafd;
    height: 84px;
    border-radius: 16px;
    cursor: pointer;
  }
  .title {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .destekTitle {
    display: flex;
    flex-direction: column;
    > span {
      margin-top: 12px;
      font-family: MicrosoftYaHeiSemibold;
      font-size: 20px;
      color: #000000;
      line-height: 27px;
      letter-spacing: 0;
    }
    > span:last-child {
      margin: 0;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #7a8793;
      height: 36px;
    }
  }
  .el-collapse-item__content {
    text-align: left;
  }
  .vui-select {
    width: 300px;
    height: 40px;
    margin: 20px;
  }
  .vui-select > .vui-input,
  .vui-select.vui-select--medium .vui-input__inner {
    height: 100%;
  }
}
.adanaContainer {
  padding: 0 20px;
  display: flex;
}
.section {
  display: flex;
  flex-direction: column;
  flex: 1;
  .label {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
  }
  p {
    // width: 420px;
    word-wrap: break-word;
    margin: 0;
  }
  .top {
    font-family: MicrosoftYaHeiSemibold;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: bold;
    // width: 350px;
    word-wrap: break-word;
  }
  .adress {
    margin-bottom: 20px;
  }
  .info {
    flex: 1;
  }
}
.section:nth-child(2) {
  margin-left: 70px;
  border-left: 1px solid lightgray;
  padding: 0 0 0 70px;
}
.article {
  margin-bottom: 20px;
}
.fileList {
  padding-left: 20px;
  .listItem {
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f7fafd;
    background: #ffffff;
    box-shadow: 0 1px 0 0 #f7fafd;
    padding: 12px 18px 12px 0;
    div {
      margin: 0 20px;
      flex: 1;
      text-align: left;
    }
    span {
      cursor: pointer;
    }
    span:hover {
      color: #1890ff;
    }
  }
  .title {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
    cursor: auto;
  }
}
.destekImg {
  width: 48px;
  height: 48px;
  margin-right: 20px;
}
</style>
